<template>
  <v-dialog v-model="dialog" width="500">
    <v-card>
      <v-card-title primary-title>
        <h5 class="headline logo_text">
          <i class="fa fa-map-marker logo_icon" />&nbsp;&nbsp; Update Marker
        </h5>
      </v-card-title>

      <v-divider />

      <v-card-text>
        <v-alert :value="errors.items.length > 0" type="error">
          <div v-for="error in errors.items" :key="error.name">
            {{ error.msg }} <br />
          </div>
        </v-alert>

        <v-tabs centered fixed-tabs>
          <v-tab ripple> <span class="logo_text">Info</span></v-tab>
          <v-tab ripple> <span class="logo_text">Location</span></v-tab>
          <v-tab ripple> <span class="logo_text">Extra</span></v-tab>
          <v-tab-item>
            <marker-info v-model="marker" />
          </v-tab-item>
          <v-tab-item>
            <marker-location
              v-model="location"
              v-validate.disable="'location:' + token"
              data-vv-scope="location"
            />
          </v-tab-item>
          <v-tab-item>
            <marker-extra v-model="extra" />
          </v-tab-item>
        </v-tabs>
      </v-card-text>

      <v-divider />

      <v-card-actions>
        <v-spacer />
        <v-btn text @click="setMarker">
          Update
        </v-btn>
        <v-btn text @click="dialog = false">
          Cancel
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapGetters } from "vuex";
import markerLocation from "./Location.vue";
import markerInfo from "./Info.vue";
import markerExtra from "./Extra.vue";
import { verifyLocation } from "./Rules/verifyLocationRule";

export default {
  name: "EditMarker",
  components: {
    markerLocation,
    markerInfo,
    markerExtra
  },
  props: {
    id: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      marker: {
        icon: "",
        pin: "",
        name: "",
        data: {
          range: {
            start: "",
            end: ""
          },
          value: ""
        }
      },
      location: {
        lat: "",
        long: "",
        city: "",
        state: "",
        country: ""
      },
      extra: {
        addInfo: "",
        link: {
          url: "",
          title: ""
        }
      },
      defaultLocation: null,
      dialog: true,
      stepper: 0,
      token: ""
    };
  },
  computed: {
    ...mapGetters(["currMap", "user"]),
    infoValid() {
      return !this.errors.items.some(item => item.scope === "marker");
    },
    locationValid() {
      return !this.errors.items.some(item => item.scope === "location");
    },
    extraValid() {
      return !this.errors.items.some(item => item.scope === "extra");
    }
  },
  watch: {
    dialog() {
      if (!this.dialog) {
        this.$emit("close");
      }
    }
  },
  async mounted() {
    this.token = await this.user.getIdToken(true);
    const marker = Object.assign(
      {},
      this.currMap.markers.find(marker => marker.id === this.id)
    );

    for (const prop in this.marker) {
      this.marker[prop] = marker[prop] ? marker[prop] : "";
    }

    this.defaultLocation = {};
    for (const prop in this.location) {
      this.location[prop] = marker.location[prop] ? marker.location[prop] : "";
      this.defaultLocation[prop] = marker.location[prop]
        ? marker.location[prop]
        : "";
    }

    for (const prop in this.extra) {
      this.extra[prop] = marker[prop] ? marker[prop] : this.extra[prop];
    }

    this.marker.id = this.id;
  },
  created() {
    this.$validator.extend("location", verifyLocation, ["token"]);
  },
  provide() {
    return { parentValidator: this.$validator };
  },
  methods: {
    async setMarker() {
      const validMarker = await this.$validator.validateAll("marker");
      const validExtra = await this.$validator.validateAll("extra");
      if (validMarker && validExtra) {
        // check if changed
        const validLocation = this.locationChanged()
          ? await this.$validator.validateAll("location")
          : true;
        if (validLocation) {
          // populate lat/long or city/state if neccassary
          await this.dispatchSetMarker();
          this.dialog = false;
        }
      }
    },

    async dispatchSetMarker() {
      let marker = Object.assign({}, this.marker, this.extra);
      marker.location = Object.assign({}, this.location);
      delete marker.action;
      await this.$store.dispatch("updateMarker", marker);
    },

    locationChanged() {
      for (const key in this.defaultLocation) {
        if (this.defaultLocation[key] !== this.location[key]) {
          return true;
        }
      }

      return false;
    }
  }
};
</script>
